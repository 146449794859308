import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import Breadcrumbs from '../components/Breadcrumbs';
import ProjectCardListing from '../components/ProjectCardListing';
import ProjectTypeSelector from '../components/ProjectTypeSelector';
import ModularBlocks from '../components/ModularBlocks';

const ProjectCategoryTemplate = ({
  data: {
    datoCmsProjectCategory: {
      seoMetaTags,
      title,
      bannerText,
      bannerImage,
      modularBlocks,
    },
    allDatoCmsProject: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <Banner heading={title} text={bannerText} image={bannerImage} />
      <Breadcrumbs
        breadcrumb={{ slug: 'projects', text: 'Projects' }}
        currentPageTitle={title}
      />
      <ProjectCardListing items={nodes} />
      <ProjectTypeSelector fullWidth={true} />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const ProjectCategoryTemplateQuery = graphql`
  query ProjectCategoryTemplateQuery($id: String!) {
    datoCmsProjectCategory(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerText
      bannerImage {
        ...SubpageBannerImageFragment
      }
      modularBlocks {
        ...ContentModularBlockFragment
        ...ImageContentModularBlockFragment
        ...VideoContentModularBlockFragment
      }
    }
    allDatoCmsProject(
      filter: { category: { elemMatch: { id: { eq: $id } } } }
      sort: { fields: position }
    ) {
      nodes {
        ...ProjectCardFragment
      }
    }
  }
`;

export default ProjectCategoryTemplate;
