import React from 'react';
import styled, {css} from 'styled-components';
import { minBreakpointQuery, sectionMargins } from '../styles';
import { Container, Heading, LineLink } from './ui';
import ProjectCard from './ProjectCard';

const StyledProjectCardListing = styled.section`
  ${sectionMargins()};
`;

const StyledHeader = styled.header`
  margin-bottom: 20px;

  ${minBreakpointQuery.small`
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 40px;
  `}
`;

const StyledInner = styled.div`
  display: grid;
  gap: 30px;
  align-items: flex-start;

  ${minBreakpointQuery.small`
    column-gap: 40px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    column-gap: 60px;
  `}

  ${minBreakpointQuery.large`
    gap: 50px 100px;
  `}

  ${minBreakpointQuery.xlarge`
    gap: 70px 140px;
  `}

  ${minBreakpointQuery.xxlarge`
    gap: 90px 180px;
  `}
`;

const StyledItem = styled(ProjectCard)`

  ${({ isProjectSingle }) => {
    if (isProjectSingle) {
      return css`

        &:first-child {

          ${minBreakpointQuery.smedium`
            margin-top: 40px;
          `}

          ${minBreakpointQuery.mlarge`
            margin-top: 60px;
          `}

          ${minBreakpointQuery.xlarge`
            margin-top: 80px;
          `}
        } 
      `;
    }
  }};
`;

const ProjectCardListing = ({ heading, link, items, isProjectSingle }) =>
  items.length > 0 && (
    <StyledProjectCardListing>
      <Container>
        {heading && (
          <StyledHeader>
            <Heading>{heading}</Heading>
            {link && <LineLink to={link}>View all</LineLink>}
          </StyledHeader>
        )}
        <StyledInner>
          {items.map(({ id, title, slug, image }) => (
            <StyledItem key={id} title={title} slug={slug} image={image} isProjectSingle={isProjectSingle} />
          ))}
        </StyledInner>
      </Container>
    </StyledProjectCardListing>
  );

export default ProjectCardListing;
