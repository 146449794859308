import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontSize,
  fontWeights,
} from '../styles';
import { Container, Link } from './ui';

const StyledBreadcrumbs = styled.div`
  margin: 30px 0;

  ${minBreakpointQuery.large`
    margin-top: 40px;
    margin-bottom: 40px;
  `}
`;

const StyledList = styled.ol``;

const StyledItem = styled.li`
  display: inline;
  color: ${brandColours.septenary};
  ${fontSize(14)};
  line-height: 1.7;

  ${({ current }) => {
    if (current) {
      return css`
        color: ${brandColours.senary};
        font-weight: ${fontWeights.bold};
      `;
    }
  }};

  &:after {
    content: '/';
    margin: 0 10px;
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const Breadcrumbs = ({ breadcrumb, currentPageTitle, ...props }) => (
  <StyledBreadcrumbs {...props}>
    <Container>
      <StyledList>
        <StyledItem>
          <StyledLink to="/">Home</StyledLink>
        </StyledItem>
        {breadcrumb && (
          <StyledItem>
            <StyledLink to={breadcrumb.slug}>{breadcrumb.text}</StyledLink>
          </StyledItem>
        )}
        <StyledItem current={true}>{currentPageTitle}</StyledItem>
      </StyledList>
    </Container>
  </StyledBreadcrumbs>
);

export default Breadcrumbs;
